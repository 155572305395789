<template>
    <layout class="index-ma-wrapper" ref="info">
        <div class="container index-container flex mt20">
            <a-spin :spinning="loading" tip="Loading..." class="home-left">
                <div class="" v-show="!loading">
                    <div v-for="(element,index) in topicSort.leftList" :key="index">
                        <div class="mb20" v-if="element.link_type_id == 1 && element.is_show == '1'">
                                <common-title :path="'/special-ma/infomation-list/'+$route.params.id" :title='element.title' type="ma"></common-title>
                                <template v-if="zixunList.length">
                                    <div v-for="(item, index) in zixunList" :key="index" class="hot-topic-box"
                                        :class="{ 'mt20': index != 0 }">
                                        <hot-topic-item containerWidth="780px" :path="'/special-ma/infomation-detail/'+$route.params.id" :type="item.type" :item="item"
                                            class="bb"></hot-topic-item>
                                    </div>
                                </template>
                                <a-empty v-else />

                        </div>
                        <div class="mb20" v-if="element.link_type_id == 2 && element.is_show == '1'">
                            <common-title :path="'/special-ma/video-list/'+$route.params.id" :title='element.title' type="ma"></common-title>
                            <template v-if="videoList.length">
                                <course-item  type="ma" lineCount="4" width="178px" height="130px"  :target-type="1" :data-list="videoList" @clickEvent="videoJumpDetail(item.id,item.listId,'ma',$route.params.id)"></course-item>
                            </template>
                            <a-empty v-else />
                        </div>
                        <div class="mb20" v-if="element.link_type_id == 3 && element.is_show == '1'">
                            <common-title :path="'/special-ma/guide-list/'+$route.params.id" :title='element.title' type="ma"></common-title>
                            <div class="list-items" v-if="guideList.length">
                                <text-item :path="'/special-ma/guide-detail/'+$route.params.id" type="2" gap="20" v-for="(item, index) in guideList" :key="index"
                                    :item="item"></text-item>
                            </div>
                            <a-empty v-else />
                        </div>
                        <div class="mb20" v-if="element.link_type_id == 8 && element.is_show == '1'">
                            <common-title :path="'/special-ma/article-list/'+$route.params.id" :title='element.title' type="ma"></common-title>
                            <div class="" v-if="paperList.length">
                                <div v-for="(item, index) in paperList" :key="index" class="paper-list pb20 t-l"
                                    :class="{ 'mt20': index != 0 }" @click="goArticleDetail(item.id)">
                                    <p class="fs16 color333 strong mb15" style="line-height:1.3;">{{ item.title || item.title_en }}</p>
                                    <div class="flex x-left color333 fs12 row-1 mb5" v-if="item.real_name || item.org_cnname">
                                        作者：
                                        <span v-if="item.real_name" class="mr10"><span
                                                class="color333">{{ item.real_name }}</span></span>
                                        <span v-if="item.org_cnname" class="color999">{{ item.org_cnname }}</span>
                                    </div>
                                    <p v-if="item.keywords" class="fs12 color333 mb10">关键词：{{ item.keywords }}</p>
                                </div>
                            </div>
                            <a-empty v-else />
                        </div>
                        <div class="mb25" v-if="element.link_type_id == 7 && element.is_show == '1'">
                            <common-title :path="'/special-ma/org-list/'+$route.params.id" :title='element.title' type="ma"></common-title>
                            <div class="flex x-left swiper-content" v-if="committeeList.length">
                                <committee-item width="177px" v-for="(item, index) in committeeList" :key="index"
                                    :item="item" type="ma"></committee-item>
                            </div>
                            <a-empty v-else />
                        </div>
                        <div class="mb20 img-list" v-if="element.link_type_id == 99 && element.is_show == '1'">
                            <a-carousel v-if="advertLeftList.length" class="carousel" arrows autoplay :autoplay-speed="4000"  style="overflow: hidden;">
                                <div slot="prevArrow" slot-scope="props" class="custom-slick-arrow" style="left: 10px;zIndex: 1">
                                    <a-icon type="left-circle" />
                                </div>
                                <div slot="nextArrow" slot-scope="props" class="custom-slick-arrow" style="right: 10px">
                                    <a-icon type="right-circle" />
                                </div>
                                <img style="cursor:pointer;" :src="item.image_url | urlFilter(750)" alt="" v-for="(item, index) in advertLeftList" @touchstart.stop="handleBannerLink(item)" @click="handleBannerLink(item)" :key="index">
                            </a-carousel>
                        </div>
                    </div>
                </div>
            </a-spin>
            <div class="home-right t-l">
                <layout-right v-if="layoutRightConfig" :config="layoutRightConfig"></layout-right>
            </div>
        </div>
        <a-empty v-if="!topicSort?.leftList?.length && !topicSort?.rightList?.length && !loading" />
    </layout>
</template>
<script>
import layout from '@/components/layout/index-ma';
import layoutRight from '@/components/layout/layout-right';
import commonTitle from '@/components/common-title';
import hotTopicItem from '@/components/hot-topic-item.vue';
import courseItem from '@/components/courser-list-item';
import textItem from '@/components/text-item.vue';
import committeeItem from '@/components/committee-item';
import { jsonFormat } from '@/utils/jsonFormat.js';
import { videoJumpDetail } from '@/utils/jumpPageMethods';
import { committee_id } from '@/config';
export default {
    name: 'SpecialMaIndex',
    components: {
        layout,
        commonTitle,
        layoutRight,
        hotTopicItem,
        courseItem,
        textItem,
        committeeItem,
    },
    data() {
        return {
            loading: false,
            topicSort:{
                leftList:[],
                rightList:[]
            },
            zixunList: [], //热点资讯列表
            videoList: [], //视频列表
            guideList: [], //指南列表
            paperList: [], //论文列表
            committeeList:[], //机构列表
            advertLeftList:[], //左侧轮播图
            advertRightList:[], //右侧轮播图
            layoutRightConfig: null,
            params:{
                limit: 3, 
                topic_id: this.$route.params.id, 
                page: 1,
                topic_committee_id:committee_id
            },
        }
    },
    computed: {
    },
    methods: {
        videoJumpDetail,
        handleBannerLink(item){
            item.skip_url &&  window.open(item.skip_url,'_blank')
        },
        // 论文详情
        goArticleDetail(id){
            this.$router.push({
                path: '/special-ma/article-detail/'+this.$route.params.id,
                query: {
                    id
                }
            })
        },
        // 热点资讯列表
        getZixunList() {
            this.request.post('GetNews',this.params).then(res => {
                this.zixunList = res.data.list
            })
        },
        // 视频列表
        getVideoList(){
            this.request.get('GetVideoListNew',{
                page: 1,
                PageSize: 4,
                no_count: false,
                topic_id: this.$route.params.id, 
                topic_committee_id: committee_id
            }).then(res=>{
                let videoObj = {
                    img: 'default_pic_url',
                    name: 'author',
                    org: 'org_cnname',
                }
                this.videoList = jsonFormat(res.data.list, videoObj)
            })
        },
        // 指南列表
        getGuideList() {
            this.request.post('GuideList',this.params).then(res => {
                let obj = {
                    id: 'guide_id',
                    news_title: 'title_cn',
                    create_time: 'publish_date',
                    source: 'reference',
                    
                }
                let guideList = jsonFormat(res.data.list, obj)
                guideList.forEach(item => {
                    item.class_type = 0
                    item.typeName = '指南'
                })
                this.guideList = guideList
            })
        },
        // 机构列表
        getCommitteeList(){
            this.request.post('TopicOrgList',{limit: 4, topic_id: this.$route.params.id, page: 1,committee_id}).then(res=>{
                let committeeObj = {
                    name:'title',
                    index_logo:'img'
                }
                this.committeeList = jsonFormat(res.data.list, committeeObj)
            })
        },
        // 论文列表
        getArticleList(){
            this.request.post('GetMeetingPaper',this.params).then(res => {
                this.paperList = res.data.list
            })
        },
        // 广告图列表
        getImgList(lb_id){
            this.request.post('TopicImgList', { topic_id: this.$route.params.id, lb_id, committee_id }).then(res => {
                this.advertLeftList = res.data
            })
        },
        sortNum(ary, key) {
			return ary.sort(function (a, b) {
			    let x = a[key]
			    let y = b[key]
			    return ((x < y) ? -1 : (x > y) ? 1 : 0)
			})
		},
        pageInit(){
            this.loading = true
            let reqArr = [
                // 获取当前首页排序
                this.request.post('TopicNavSort', {topic_id: this.$route.params.id,committee_id}),
            ]
            Promise.all(reqArr).then(res => {
                if(res[0].data != ''){
                    let topicSort = res[0].data || {}
                    topicSort['2'] && (topicSort['leftList'] = this.sortNum(topicSort['2'],'pre_sort_num'))
                    topicSort['3'] && (topicSort['rightList'] = this.sortNum(topicSort['3'],'pre_sort_num'))
                    this.topicSort = topicSort
                    if(topicSort && topicSort['2']?.length){
                        topicSort['leftList'].forEach((item,index)=>{
                            // link_type_id 1;//资讯  2;//视频  3;//指南 4;//期刊 5;//会议 6;//专家 7;//机构 8论文
                            if(item.link_type_id == 1 && item.is_show == '1'){
                                this.getZixunList()
                            }else if(item.link_type_id == 2 && item.is_show == '1'){
                                this.getVideoList()
                            }else if(item.link_type_id == 3 && item.is_show == '1'){
                                this.getGuideList()
                            }else if(item.link_type_id == 8 && item.is_show == '1'){
                                this.getArticleList()
                            }else if(item.link_type_id == 7 && item.is_show == '1'){
                                this.getCommitteeList()
                            }else if (item.link_type_id == 99 && item.is_show == '1') {
                                this.getImgList(item.id)
                            }
                        })
                    }
                    if(topicSort && topicSort['3']?.length){
                        let layoutContent = {}
                        topicSort['rightList'].forEach((item,index)=>{
                            // link_type_id 1;//资讯  2;//视频  3;//指南 4;//期刊 5;//会议 6;//专家 7;//机构 8论文
                            if(item.link_type_id == 6 && item.is_show == '1'){
                                layoutContent['recommendExpert'] = {
                                    show: true, // 是否显示
                                    title: item.title, // 标题
                                    methods: "post",
                                    moreLink: '/special-ma/expert-list/'+this.$route.params.id,
                                    type: 'ma',
                                    data: {
                                        limit: 3, 
                                        topic_id: this.$route.params.id, 
                                        page: 1,
                                        topic_committee_id:committee_id
                                    }   // 参数条件
                                }
                            }
                            if(item.link_type_id == 5 && item.is_show == '1'){
                                layoutContent['meeting'] = {
                                    show: true, // 是否显示
                                    title: item.title, // 标题
                                    methods: 'get',
                                    moreLink: '/special-ma/meeting-list/'+this.$route.params.id,
                                    type: 'ma',
                                    data: {
                                        page: 1,
                                        pageSize: 3,
                                        topic_id: this.$route.params.id, 
                                        topic_committee_id: committee_id
                                    }   // 参数条件
                                }
                            }
                            if(item.link_type_id == 4 && item.is_show == '1'){
                                layoutContent['periodicalRank'] = {
                                    show: true, // 是否显示
                                    methods: 'post',
                                    title: item.title, // 标题
                                    moreLink: '/special-ma/periodical-list/'+this.$route.params.id,
                                    detailLink:'/special-ma/periodical-detail/'+this.$route.params.id,
                                    type: 'ma',
                                    data: {
                                        page: 1,
                                        limit: 3, 
                                        topic_id: this.$route.params.id, 
                                        topic_committee_id: committee_id
                                    },   // 参数条件
                                }
                            }
                            if(item.link_type_id == 99 && item.is_show == '1'){
                                layoutContent['lbList'] = {
                                    show: item.is_show, // 是否显示
                                    methods: 'post',
                                    title: '', // 标题
                                    type: 'ma',
                                    data: {
                                        topic_id: this.$route.params.id, 
                                        lb_id:item.id,
                                        committee_id
                                    },   // 参数条件
                                }
                            }
                        })
                        this.layoutRightConfig = layoutContent
                    }
                }
                this.loading = false
                
            }).catch((err) => { console.log(err) })

        }
    },
    created() {
        
    },
    mounted() {
        this.pageInit()
    },
    

    // 缓存页面二次进入的钩子函数
    activated() {

    },
}
</script>
<style lang="less" scoped>
.index-ma-wrapper {
    .home-left {
        width: calc(66.66% - 30px);

        .paper-list {
            border-bottom: 1px dashed #d8d8d8;
            cursor: pointer;

            &:last-child {
                border: 0;
            }
        }
        .carousel{
            height: 300px;
            img{
                width: 100%;
                height: 300px;
                display: block;
                object-fit: cover;
            }
            .slick-dots-bottom{
                bottom: 20px;
            }
        }
    }

    .home-right {
        width: 33.33%;
    }
}

@media screen and (max-width:768px) {
    .container {
        display: block;
        padding: 0 10px;
    }
    .index-ma-wrapper{
        .home-left,.home-right{
            width: 100%;
        }
        .home-left{
            .swiper-content{
                flex-wrap: wrap;
                .committee-item{
                    width: calc(50% - 10px)!important;
                    &:nth-child(2n){
                        margin-right: 0;
                    }
                }
            }
            .carousel{
                height: auto;
                img{
                    width: 100%;
                    height: 100%;
                    min-height: 100px;
                    display: block;
                    object-fit: cover;
                }
                .slick-dots-bottom{
                    bottom: 10px;
                }
            }
        }
    }
}
</style>
